import { render, staticRenderFns } from "./NotifConfig.vue?vue&type=template&id=17ea9d7c&scoped=true&"
import script from "./NotifConfig.vue?vue&type=script&lang=js&"
export * from "./NotifConfig.vue?vue&type=script&lang=js&"
import style0 from "./NotifConfig.vue?vue&type=style&index=0&id=17ea9d7c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17ea9d7c",
  null
  
)

export default component.exports