<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div v-if="apiLoaded || errorText">
        <div v-if="apiLoaded" class="wrap">
          <v-textarea v-model="template" label="Шаблон электронного письма"></v-textarea>
          <v-text-field label="Host" value="smtp.office365.com"></v-text-field>
          <v-text-field label="Port" value="587"></v-text-field>
          <v-text-field label="From" value="czn2.0@vcot.info"></v-text-field>
          <v-text-field label="Username" value="tech_001_czn@vcot.info"></v-text-field>
          <v-text-field label="Password" value="*******" type="password"></v-text-field>
          <v-btn color="primary" @click="submit">Сохранить</v-btn>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
      <div v-else style="height:1000px"></div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
</style>

<script>

export default {
  props: {},
  components: {},
  data() {
    return {
      apiLoaded: true,
      errorText: null,
      success: false,
      template: null
    };
  },
  filters: {},
  methods: {
    async getTemplate(){
      let req = await this.$getApi("/notifs/getEmailTemplate",
          {userName: this.$user.username})
      if (req.ok) {
        this.template = req.payload.template
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    async submit(){
      let req = await this.$postFormDataApi("/notifs/setEmailTemplate", {template: this.template})
      if (req.ok) {
        this.$router.push('/config/messages')
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.loading = false
    }
  },
  async beforeMount() {
    await this.getTemplate()
    this.apiLoaded = true
  }
};
</script>
